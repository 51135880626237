import React from "react"
import * as Constants from "./CompareConstants"
import classNames from "classnames"

// Компонент для отрисовки заголовка таблицы с названиями комплектаций
// у каждой комплектации есть галочка, которая скроет эту комплектацию в таблице
// @param visible_variants - видимые комплектации
// @param variants - глобальный объект комплектаций
// @param setParamsHandler - функция, для изменения GET-параметров
// @param crossHovered - массив из двух элементов, ряд и номер ячейки на которую
//                       наведён курсор мыши, для подстветки
export default function CompareVariantNames (props) {
  function optOutVariantHandler(event, variant) {
    event.preventDefault()
    const variant_index = props.variants.list.indexOf(variant)

    props.setParamsHandler(prevParams => {
      const current_value = prevParams.get(Constants.FILTER_VARIANTS_PARAM) || 0
      prevParams.set(Constants.FILTER_VARIANTS_PARAM, current_value | Math.pow(2, variant_index))
      return prevParams
    })
  }

  function onClickHandler(event, variant) {
    window.stateMachine.pushState({model: props.variants.model_id, variant: variant.id, tab: ""}, decodeURI(variant.link))
    event.preventDefault()
  }

  return props.variants.list.map((variant, i) => {
    return  <th key={i} className={classNames({
                  'variant-name': true,
                  'text-center': true,
                  'hidden-column': variant.hidden,
                  'cross-hover': props.visible_variants.length > Constants.VERTICAL_CROSS_HOVER_MIN && props.crossHovered[1] == i
                })}>
              <a href={variant.link} onClick={(e) => onClickHandler(e, variant)}>{variant.name}</a>
              {props.visible_variants.length > 1 && <a className="close" href="#" onClick={(e) => optOutVariantHandler(e, variant)}>×</a>}
            </th>
  })
}