import React from "react"
import {HIDESAME_PARAM} from './CompareConstants'

// Компонент для отрисовки галочки "Показать отличия"
// @param visible_variants - список всех выводимых комплектаций
// @param value - текущее значение галочки, true/false
// @param setParamsHandler - функция, для изменения GET-параметров
export default function CompareToggleDifferences (props) {
  function toggleHideSame(event) {
    props.setParamsHandler(function(prevParams) {
      if (event.target.checked) {
        prevParams.set(HIDESAME_PARAM, 1)
      }
      else {
        prevParams.delete(HIDESAME_PARAM)
      }
      return prevParams
    })
    return true
  }

  return (
    <form>
      <div className="form-check ml-4">
        <input
          type="checkbox"
          name="compare_toggle_checkbox"
          id="compare_toggle_checkbox"
          checked={props.value}
          disabled={props.visible_variants.length < 2}
          className="compare-toggle form-check-input mt-2 zoom-20"
          onChange={toggleHideSame} />
        <label
          className="form-check-label font-weight-normal"
          htmlFor="compare_toggle_checkbox">
            Показать отличия
        </label>
      </div>
    </form>
  )
}