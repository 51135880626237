// "специальный" параметр для галочки "Показать отличия", его название в URL.search
export const HIDESAME_PARAM = 'hidesame'
// "специальный" параметр для скрытия комплектаций. Принимает цифровое значение
// которое является битовой маской. Скрытие первой комплектации - первый бит справа 0b1 = 1,
// скрытие второй комплектации - второй бит справа (0b10=2), скрытие и первой и второй
// комплектации - первый и второй бит (0b11 = 3)
export const FILTER_VARIANTS_PARAM = 'filter_variants'
// "специальный" параметр для разворачивания свёрнутых характеристик при включенной
// галочке "Показать отличия", тоже работает как битовая маска
export const COLLAPSE_PARAM = 'collapse'

export const COMPLETIONS_NAMES = ["комплектация", "комплектации", "комплектаций"]
export const HIDDEN_NAMES = ["скрыта", "скрыто", "скрыто"]
export const SAME_NAMES = ["одинаковая", "одинаковых", "одинаковых"]
export const DIFFERENT_NAMES = ["разная", "разных", "разных"]

export const COLLAPSE_TEXT = "свернуть ▴"
export const EXPAND_TEXT = "развернуть ▾"

export const VERTICAL_CROSS_HOVER_MIN = 2