import React from "react"
import classNames from "classnames"

// Компонент для отрисовки ячеек таблицы со значениями характеристик
// @param categoryKey - идентификатор категории и подкатегории
// @param cells - список ячеек, массив
// @param variants - глобальный объект комплектаций
// @param visible_variants - видимые комплектации
// @param propName - название характеристики, строка, например: "Длина кузова"
// @param searchParams - объект GET-параметров, тип URLSearchParams
// @param setParamsHandler - функция, для изменения GET-параметров
// @param crossHovered - массив из двух элементов, ряд и номер ячейки на которую
//                      наведён курсор мыши, для подстветки
// @param setCrossHovered - функция, для изменения crossHovered
export default function CompareTableCells (props) {
  // Если есть различающиеся параметры у видимых комплектаций
  const may_click = _.unique(props.cells.filter((a, i) => !props.variants.list[i].hidden)).length > 1

  function onClickHandler(event, key, value) {
    event.stopPropagation()
    props.setParamsHandler(function(prevParams) {
      if (prevParams.get(key)) {
        prevParams.delete(key)
      }
      else {
        prevParams.set(key, value)
      }
      return prevParams
    })
  }

  function onMouseOverHandler(event, index) {
    props.setCrossHovered(prevHover => {
      return [props.categoryKey, index]
    })
  }

  return props.cells.map((item, i) => {
    const is_hidden = props.variants.list[i].hidden // эта комплектация скрыта
    const key = props.variants.urlparams[props.propName]
    const value = props.variants.urlparams[item]
    const is_checked = props.searchParams.get(key) == value
    return (
      <td 
        className={
          classNames({
            'text-center': true,
            'hidden-column': is_hidden,
            'cross-hover': props.crossHovered[1] == i && props.visible_variants.length > 2
          })
        }
        key={i}
        title={may_click ? "выбрать все с таким параметром": ""}
        onClick={may_click? (e) => onClickHandler(e, key, value): null}
        onMouseOver={(e) => onMouseOverHandler(e, i)}
        >
        { may_click && <input className="selectable-checkbox"
                              type="checkbox"
                              checked={is_checked}
                              readOnly={true}
                               />
          // Чекбокс намеренно заведён под следующий элемент и некликабельный.
          // Отрисовка состояния чекбокса производится реактом.
        }
        <div>{item == ' '? '–': item}</div>
      </td>
    )
  })
}