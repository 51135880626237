import React from "react"
import * as Constants from "./CompareConstants"

// Компонент для отрисовки блока с выбранными фильтрами и кнопки возврата в исходное состояние
// @param variants - глобальный объект комплектаций
// @param searchParams - объект GET-параметров, тип URLSearchParams
// @param setParamsHandler - функция, для изменения GET-параметров
export default function CompareFilter (props) {
  const overall_count = props.variants.list.length
  const hidden_count = props.variants.list.filter(v => v.hidden).length
  const visible_count = overall_count - hidden_count

  function resetFilterHandler(e) {
    e.preventDefault()
    props.setParamsHandler(prevParams => {
      [...prevParams.entries()].forEach(([key, value]) => prevParams.delete(key))
      return prevParams
    })
  }

  function filterClickHandler(event, item) {
    event.preventDefault()
    props.setParamsHandler(prevParams => {
      prevParams.delete(item.param)
      return prevParams
    })
  }

  function getFilterNames(key, value) {
    const value_name = Object.keys(props.variants.urlparams).find(k => props.variants.urlparams[k] === value)
    const key_name = Object.keys(props.variants.urlparams).find(k => props.variants.urlparams[k] === key)

    let name = value_name
    let full_name = key_name + ': ' + value_name

    if (key == 'filter_variants') {
      const hidden_variants_count = parseInt(value).toString(2).match(/1/g).length
      name = `- ${hidden_variants_count}  ${russianPlural(hidden_count, ...Constants.COMPLETIONS_NAMES)}`
      full_name = 'Скрытые комплектации'
    }
    else if (value == 'yes') {
      // это чекбокс
      name = key_name
      full_name = key_name + ': есть'
    }
    else if (value == 'no') {
      // это чекбокс
      name = key_name
      full_name = key_name + ': нет'
    }

    return [name, full_name]
  }
  const base_url = location.href.split('?')[0]

  let filter_items = [...props.searchParams.entries()].map(([key, value]) => {
    if (key == 'hidesame' || key == 'collapse') return null
    const [name, full_name] = getFilterNames(key, value)
    // Для каждого элемента фильтра нужно сгенерировать ссылку, при открытии которой
    // будет такой же фильтр, но без параметра этого элемента
    const target_params = new URLSearchParams(props.searchParams.toString())
    target_params.delete(key)
    const target_params_string = target_params.toString()
    return {
      name: name,
      full_name: full_name,
      param: key,
      href: target_params_string.length > 0? '?' + target_params_string: base_url
    }
  }).filter(i => i)

  return (
    <div>
      <h5>Всего: <strong>{overall_count}</strong> {
        hidden_count > 0
          ? <>
              &nbsp;
              выбрано: <strong>{visible_count}</strong>
              &nbsp;&nbsp;
              {russianPlural(hidden_count, ...Constants.HIDDEN_NAMES)}: <strong>{hidden_count}</strong>
              &nbsp;
              {russianPlural(hidden_count, ...Constants.COMPLETIONS_NAMES)}
            </>
          : <>{russianPlural(overall_count, ...Constants.COMPLETIONS_NAMES)}</>
      }
      </h5>
      {hidden_count > 0 && <a className="variants-show-all" href="#" onClick={(e) => resetFilterHandler(e)}>вернуть скрытые</a>}
      <div className="filter-links">
        { filter_items.length > 0 && <span className="title">Выбранные опции:</span> }
        {
          filter_items.map(item => {
            return (
              <a className="js-link"
                 key={item.param}
                 title={item.full_name}
                 onClick={(e) => filterClickHandler(e, item)}
                 href={item.href}>
                <span>{item.name}</span>
              </a>
            )
          })
        }
      </div>
    </div>
  )
}