import React, { useMemo, useState, useEffect } from "react"
import CompareFilter from './CompareFilter'
import CompareToggleDifferences from './CompareToggleDifferences'
import CompareVariantNames from './CompareVariantNames'
import CompareCategory from './CompareCategory'
import { updateVariantsData, useControllerSearchParams } from "./CompareHelper"
import classNames from "classnames"
import * as Constants from "./CompareConstants"

// Компонент для прорисовки всей таблицы
// @param variants - массив из комплектаций в виде {name: <название>, price: <цена>}
// @param variants_data - массив из категорий характеристик в виде {name: <название>, children: <массив характеристик>}
// @param urlparams - Hashmap из всех текстов (в ключах) и соответствие их SEO-написанию (в значениях)
// @param model_id - ID модели в базе
// @param model_name - название модели
export default function CompareTable (props) {
  // параметры из GET-запроса, а также callback для их дальнейшего изменения
  let [searchParams, setSearchParams] = useControllerSearchParams(props.model_id, props.link);

  // Состояние с текущими данными комплектаций и их характеристик, а также callback для их изменения
  let [variants, setVariants] = useState(
                                  // мы должны провести первоначальную обработку данных, чтобы текущий открытый URL
                                  // влиял на выводимые данные.
                                  // Далее состояние будет меняться при помощи setVariants(prev => ...)
                                  updateVariantsData(props, searchParams)
                                )

  // Хука, которая следит за изменением состояния searchParams
  useEffect(() => {
    // здесь происходит "магия" - в зависимости от накликанных юзером параметров, мы меняем данные по комплектациям
    // и затем меняем состояние, в результате запускается рендер всего компонента и React меняет соответствующий HTML
    setVariants(prevVariants => updateVariantsData(prevVariants, searchParams));
  }, [searchParams])

  // Состояние для управления текущей подсвечиваемой строкой и столбцом, куда пользователь поставил курсор мыши
  let [crossHovered, setCrossHovered] = useState([null, null])

  const visible_variants = variants.list.filter((v) => !v.hidden)

  return (
    <table className="compare">
      <thead key="compare-head">
        <tr key="variants-title" className="variants-title">
          <th className="compare-title" rowSpan="2" width="300">
            <h3>{props.model_name}</h3>
            <h4>{props.model_body}</h4>
          </th>
          <th className="compare-filter" colSpan={variants.list.length}>
            <CompareFilter
              variants={variants}
              searchParams={searchParams}
              setParamsHandler={setSearchParams} />
          </th>
        </tr>
        <tr key="variants-items" className="variants-items">
          <CompareVariantNames 
              visible_variants={visible_variants}
              variants={variants} 
              setParamsHandler={setSearchParams}
              crossHovered={crossHovered} />
        </tr>
        <tr key="price-cat" className="price-cat">
          <th className="compare-toggle">
            <CompareToggleDifferences 
              visible_variants={visible_variants}
              value={searchParams.get('hidesame') == 1}
              setParamsHandler={setSearchParams} />
          </th>
          {
            variants.list.map((variant, i) => {
              return (
                <th key={i} className={classNames({
                  'variant-name': true,
                  'text-center': true,
                  'hidden-column': variant.hidden,
                  'cross-hover': visible_variants.length > Constants.VERTICAL_CROSS_HOVER_MIN && crossHovered[1] == i
                })}>
                  {variant.price}
                </th>
              )
            })
          }
        </tr>
      </thead>

      <tbody key="compare-body">
        {variants.data.map((category, category_index) => {
          return <CompareCategory
                    key={category_index}
                    category={category}
                    category_index={category_index}
                    visible_variants={visible_variants}
                    variants={variants}
                    searchParams={searchParams}
                    setParamsHandler={setSearchParams} 
                    crossHovered={crossHovered}
                    setCrossHovered={setCrossHovered} />
        })}
      </tbody>
    </table>
  )
}
