import React, { Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

const CompareTable = React.lazy(() => import('./CompareTable'))

// Базовый компонент для сравнения комплектаций
export default function CompareRoot (props) {
  return (
    <Router>
      <Routes>
        {/*<Route path={`${root_path}/*`} element={<CompareTable {...props} />} />*/}
        <Route path="*" element={
          <Suspense fallback={<div id="loading"><div></div></div>}>
            <CompareTable {...props} />
          </Suspense>
        } />
      </Routes>
    </Router>
  )
}
