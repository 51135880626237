import React from "react"
import CompareTableCells from './CompareTableCells'
import * as Constants from "./CompareConstants"
import classNames from "classnames"

// Компонент для отрисовки блока с выбранными фильтрами и кнопки возврата в исходное состояние
// @param key - идентификатор категории и подкатегории, соединённые точкой, пример: "0.0", "1.3"
// @param category - объект категории, содержит ключи .name и .children
// @param category_index - порядковый номер категории в массиве
// @param visible_variants - видимые комплектации
// @param variants - глобальный объект комплектаций
// @param searchParams - объект GET-параметров, тип URLSearchParams
// @param setParamsHandler - функция, для изменения GET-параметров
// @param crossHovered - массив из двух элементов, ряд и номер ячейки на которую
//                      наведён курсор мыши, для подстветки
// @param setCrossHovered - функция, для изменения crossHovered
export default function CompareCategory (props) {
  const visible_children = props.category.children.filter(i => props.visible_variants.length < 2 || !i.hidden)
  const total_count = props.category.children.length
  const visible_count = visible_children.length
  const hidden_count = total_count - visible_count

  const expanded = props.searchParams.get(Constants.COLLAPSE_PARAM) & Math.pow(2, props.category_index)

  function toggleCategoryHandler(category_index) {
    props.setParamsHandler(prevParams => {
      const currentValue = prevParams.get(Constants.COLLAPSE_PARAM) || 0
      let new_value = 0
      if (currentValue & Math.pow(2, category_index)) {
        new_value = currentValue ^ Math.pow(2, category_index)
      }
      else {
        new_value = currentValue | Math.pow(2, category_index)
      }
      if (new_value > 0) {
        prevParams.set(Constants.COLLAPSE_PARAM, new_value)
      }
      else {
        prevParams.delete(Constants.COLLAPSE_PARAM)
      }
      return prevParams
    })
  }

  return [
    <tr key={`cat-${props.category.name}`} className="cat-name">
      <td colSpan={props.variants.list.length + 1}
          onClick={visible_children.length < props.category.children.length ? (e) => toggleCategoryHandler(props.category_index) : null}>
        <div>
          {props.category.name}
          {visible_children.length < props.category.children.length && 
            <>
              <span className="same-count">
                {hidden_count > 0 && <>{hidden_count} {russianPlural(hidden_count, ...Constants.SAME_NAMES)} и&nbsp;</>}
                {visible_count > 0 && <>{visible_count} {russianPlural(hidden_count, ...Constants.DIFFERENT_NAMES)} опций из&nbsp;</>}
                {total_count}
              </span>
              <span className="expand-same">{expanded? Constants.COLLAPSE_TEXT: Constants.EXPAND_TEXT}</span>
            </>
          }
        </div>
      </td>
    </tr>,
    // В случае если характеристики "развёрнуты", то выводим все, но добавляем им класс compare-same
    // чтобы одинаковые значения были серенькими
    // Если не развёрнуты, то выводим все.
    ...(props.category.children).map((category_item, i) => {
      const class_names = classNames({
        "sub-param": true,
        "compare-same": expanded && category_item.hidden,
        "hidden-row": props.visible_variants.length > 1 && category_item.hidden && !expanded,
        "cross-hover": props.visible_variants.length > Constants.VERTICAL_CROSS_HOVER_MIN && props.crossHovered[0] == `${props.category_index}.${i}`
      })
      return (
                <tr key={`cat-${props.category.name}-${category_item.name}`} className={class_names}>
                  <td className="sub-param-key" key="name">
                      <div>{category_item.name}</div>
                  </td>
                  <CompareTableCells
                    categoryKey={`${props.category_index}.${i}`}
                    cells={category_item.children}
                    variants={props.variants}
                    visible_variants={props.visible_variants}
                    propName={category_item.name}
                    searchParams={props.searchParams}
                    setParamsHandler={props.setParamsHandler}
                    crossHovered={props.crossHovered}
                    setCrossHovered={props.setCrossHovered} />
                </tr>
              )
    })
  ]
}
