import * as Constants from "./CompareConstants"
import { useState } from "react"

// Основная функция, помечает видимость комплектациям и отдельным характеристикам,
// по которым затем реакт отрисовывает таблицу и производит манипуляции в DOM
// @param variants - данные, полученные в JSON при отрисовке компонента CompareVehicle
// @param searchParams - объект типа URLSearchParams, параметры текущего GET-запроса
export function updateVariantsData(variants, searchParams) {
  // Снимаем ограничение видимости со всех комплектаций
  variants.list.forEach(variant => {
    variant.hidden = false
  });

  // Скрытие комплектаций по битовой маске
  const filter_variants = searchParams.get(Constants.FILTER_VARIANTS_PARAM)
  if (filter_variants > 0) {
    variants.list.forEach((variant, index) => {
      if (filter_variants & Math.pow(2, index)) {
        variant.hidden = true
      }
    });
  }

  // Проходим по всем оставшимся значениям кроме "специальных"
  const rest_params = [...searchParams.entries()]
                        .filter(item => [
                          Constants.FILTER_VARIANTS_PARAM,
                          Constants.HIDESAME_PARAM,
                          Constants.COLLAPSE_PARAM].indexOf(item[0]) == -1
                        )
  rest_params.map(([key, value]) => {
    variants.data.forEach(cat => {
      cat.children.forEach(cat_item => {
        if (variants.urlparams[cat_item.name] == key) {
          // Отфильтровываем все комплектации у которых значение отличается от выбранного в URL
          cat_item.children.forEach((item_value, variant_index) => {
            if (variants.urlparams[item_value] != value) {
              variants.list[variant_index].hidden = true
            }
          })
        }
      })
    })
  })

  if (searchParams.get(Constants.HIDESAME_PARAM)) {
    // Скрываем все характеристики где параметры одинаковые
    variants.data.forEach(cat => {
      cat.children.forEach(cat_item => {
        // Находим уникальные значения для всех видимых комплектаций
        if (_.unique(cat_item.children.filter((a, i) => !variants.list[i].hidden)).length == 1) {
          cat_item.hidden = true
        }
        else {
          cat_item.hidden = false
        }
      })
    })
  }
  else {
    // Возвращаем видимость всем характеристикам
    variants.data.forEach(cat => {
      cat.children.forEach(cat_item => {
        cat_item.hidden = false
      })
    })
  }

  return variants
}

export function useControllerSearchParams(model_id, link) {
  let [searchParams, setSearchParams] = useState(() => new URLSearchParams(location.search))

  const stateMachineSetSearchParams = function(callback) {
    setSearchParams(prevParams => {
      // Необходимо создать новый объект URLSearchParams чтобы react видел что объект изменился
      const newParams = callback(new URLSearchParams(prevParams.toString()))

      if (window.stateMachine.compare) {
        let search = newParams.toString()
        window.stateMachine.pushState({model: model_id, compare: true}, link + (search.length > 0? '?' + search: ''))
      }
      return newParams
    })
  }
  if (!window.stateMachine.compare_set) {
    window.stateMachine.compare_set = setSearchParams
  }

  return [searchParams, stateMachineSetSearchParams]
}